.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 22px;
  width:30px;
  background-color: #262626;
}

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background: #cccaca;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
