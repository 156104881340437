.playlistContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;

  .playlist {
    text-align: left;
    z-index: 2;
    overflow-y: auto;
    padding: 0;
    margin-top: 0;
  }

  .infoPopup {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 400;
    width: 90%;
    max-width: 400px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;

    border-radius: 10px;

    .closeBtn {
      align-self: flex-end;
      width: 60px;
      height: 60px;
      margin: 10px 10px 5px 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .trackInfo {
      margin-top: -20px;
    }

    .actions {
      margin-top: 20px;
      margin-bottom: 20px;

      .trackButton {
        width: 100%;
        text-align: center;
        padding: 1.11111rem 2.22222rem 1.16667rem 2.22222rem;
        background: linear-gradient(90deg, rgba(190,53,11,1) 0%, rgba(251,139,0,1) 100%);
        border: 0px solid!important;
        text-transform: uppercase;
        font-size: 0.77778rem;
        border-color: #ffffff;
        color: #ffffff;
        cursor: pointer;

        &:hover{
          opacity: 1;
          background: linear-gradient(
                          90deg
                  , rgba(251,139,0,1) 0%, rgba(190,53,11,1) 100%);
          border: 0px solid!important;
          border-color: #ffffff;
        }

        &:focus {
          background-color: rgba(0,0,0, 0.6);
        }
      }
    }
  }

  .errorBar {
    background-color: #fd8a8a;
    color: #fff;
    list-style: none;
    width: 100%;
    margin: 0;
    overflow: hidden;
    z-index: 9999;
    -webkit-transition: opacity 1s ease;
    -o-transition: opacity 1s ease;
    transition: opacity 1s ease;
    font-size: 0.72222rem;
    line-height: 0.94444rem;
    font-weight: bold;
    padding: 20px;
    cursor: pointer;

    .inner {
      transition: opacity .5s;
      padding: 0px 35px 0 10px;
      margin-left: 20px;
      a {
        color: #FFFFFF;
      }
    }
    &.errorBar:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-exclamation-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z'/%3E%3C/svg%3E");
      color: #FFFFFF;
      display: block;
    }
  }

  input {
    background: rgba(0,0,0, 0.05);
    color: #000000;
    width: calc(100% - 1.6rem);
    height: 45px;
    font-weight: bold;
    padding-left: 0.83333rem;
    padding-right: 0.83333rem;
    outline: none;
    border: 0px solid!important;
    margin-bottom: 10px;

    &:focus &:hover {
      background: rgba(0,0,0, 0.1);
      outline: 0;
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}
