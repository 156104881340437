.playlistItem {
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 25px;

  &.first {
    background: #ff5722;
  }

  &.selected {
    background: #ffffff26;
  }

  &.sortable-ghost {
    background: #ffffff26;
  }

  &.sortable-chosen {
    background: #ffffff26;
  }

  &.sortable-drag {
    background: #ff5722;
    border-top: none !important;
  }

  .songDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .action {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 25px;
      height: 25px;

      &.play {
        width: 19px;
        height: 19px;
      }
    }
  }

}

.playlistItem + .playlistItem {
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid black;
}
