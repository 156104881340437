.loginPage {
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #202020;
  font-family: arcamajora, -apple-system, BlinkMacSystemFont, Arial, Helvetica, 'Helvetica Neue', Verdana, sans-serif;;
  overflow: auto;

  .blur {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    width:100%;
    height:100%;
    background-size:cover;
    background-color: rgba(0, 0, 0, 0.69);
    backdrop-filter: blur(5px);
  }

  .overwriteContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 400px;
    min-height: 150px;
    height: auto;
    transform: translate(-50%, -50%);
    z-index: 300;
    width: 80%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255,0.85) 100%);

    h3 {
      margin: 20px;
      font-size: 13px;
    }

    .overwriteBtn {
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
      padding: 1.11111rem 2.22222rem 1.16667rem 2.22222rem;
      background: linear-gradient(90deg, rgba(190,53,11,1) 0%, rgba(251,139,0,1) 100%);
      border: 0px solid!important;
      text-transform: uppercase;
      font-size: 0.77778rem;
      border-color: #ffffff;
      color: #ffffff;
      cursor: pointer;

      &:hover{
        opacity: 1;
        background: linear-gradient(
                        90deg
                , rgba(251,139,0,1) 0%, rgba(190,53,11,1) 100%);
        border: 0px solid!important;
        border-color: #ffffff;
      }

      &:focus {
        background-color: rgba(0,0,0, 0.6);
      }
    }
  }

  .logoContainer {

    .loginLogo {
      width: 200px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }


  .loginBox {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 4;

    max-width: 400px;
    width: 80%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255,0.85) 100%);
    display: flex;
    flex-direction: column;

    .loginHeader {
      display: flex;
      color: white;
      height: 45px;
      width: 100%;
      line-height: 36px;
      background: #2c2c2c;
      align-items: center;

      h3 {
        margin-left: 30px;
        font-size: 13px;
      }
    }

    .loginBody {
      flex-direction: row;
      display: flex;
    }
    form {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }

    p {
      margin-top: auto;
      text-align: left;
      width: 195px;
    }

    label {
      width: 100%;
      text-align: left;
      font-size: 0.66667rem;
      margin-top: 15px;
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    input {
      background: rgba(0,0,0, 0.05);
      color: #000000;
      width: 100%;
      height: 45px;
      font-weight: bold;
      padding-left: 0.83333rem;
      padding-right: 0.83333rem;
      outline: none;
      border: 0px solid!important;

      &:focus &:hover {
        background: rgba(0,0,0, 0.1);
        outline: 0;
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    .passwordBox {
      width: 100%;
      i {
        float: right;
        margin-right: 40px;
        margin-top: -32px;
        position: relative;
        z-index: 2;
      }

      input{

      }
    }


    .loginBtn {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 25px;
      text-align: center;
      padding: 1.11111rem 2.22222rem 1.16667rem 2.22222rem;
      background: linear-gradient(90deg, rgba(190,53,11,1) 0%, rgba(251,139,0,1) 100%);
      border: 0px solid!important;
      text-transform: uppercase;
      font-size: 0.77778rem;
      border-color: #ffffff;
      color: #ffffff;
      cursor: pointer;

      &:hover{
        opacity: 1;
        background: linear-gradient(
                        90deg
                , rgba(251,139,0,1) 0%, rgba(190,53,11,1) 100%);
        border: 0px solid!important;
        border-color: #ffffff;
      }

      &:focus {
        background-color: rgba(0,0,0, 0.6);
      }
    }

    .languageToggle {
      margin-bottom: 10px;
    }
  }

  .demoBox {
    margin-top: 20px;
    z-index: 2;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    max-width: 400px;
    margin-bottom: 20px;
    width: 80%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.85) 100%);
    display: flex;
    flex-direction: column;

    .demoHeader {
      display: flex;
      color: white;
      height: 45px;
      width: 100%;
      line-height: 36px;
      background: #2c2c2c;
      align-items: center;

      h3 {
        margin-left: 30px;
        font-size: 13px;
      }
    }

    .demoBody {
      flex-direction: column;
      display: flex;
      padding: 20px 30px;
      font-size: 14px;

      .demoBtn {
        width: 100%;
        margin-top: 10px;
        text-align: center;
        padding: 1.11111rem 2.22222rem 1.16667rem 2.22222rem;
        background: linear-gradient(90deg, rgba(190,53,11,1) 0%, rgba(251,139,0,1) 100%);
        border: 0px solid!important;
        text-transform: uppercase;
        font-size: 0.77778rem;
        border-color: #ffffff;
        color: #ffffff;
        cursor: pointer;

        &:hover{
          opacity: 1;
          background: linear-gradient(
                          90deg
                  , rgba(251,139,0,1) 0%, rgba(190,53,11,1) 100%);
          border: 0px solid!important;
          border-color: #ffffff;
        }

        &:focus {
          background-color: rgba(0,0,0, 0.6);
        }
      }
    }
  }

  .errorBar {
    background-color: #fd8a8a;
    color: #fff;
    list-style: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    overflow: hidden;
    z-index: 9999;
    -webkit-transition: opacity 1s ease;
    -o-transition: opacity 1s ease;
    transition: opacity 1s ease;
    font-size: 0.72222rem;
    line-height: 0.94444rem;
    font-weight: bold;
    padding: 20px;
    cursor: pointer;

    .inner {
      transition: opacity .5s;
      padding: 0px 35px 0 10px;
      margin-left: 20px;
      a {
        color: #FFFFFF;
      }
    }

    a.close {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-x-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
      position: absolute;
      right: 20px;
      top: 22px;
      width: 15px;
      height: 15px;
      padding: 0;
    }

    &.errorBar:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-exclamation-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z'/%3E%3C/svg%3E");
      color: #FFFFFF;
      display: block;
      position: absolute;
      top: 18px;
      left: 20px;
    }
  }
}
