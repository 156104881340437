.playerContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-color: #202020;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  &.mobile {
    flex-direction: column;
  }

  .playerBody {
    flex: auto;
    display: flex;
    flex-direction: row;
    background-color: #202020;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .loadingSpinner {
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px;

    .loadingText {
      color: #FFFFFF;
      font-size: 13px;
      margin-top: 20px;
    }

    .lds-roller {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-roller div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
    }
    .lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #333333;
      margin: -4px 0 0 -4px;
    }
    .lds-roller div:nth-child(1) {
      animation-delay: -0.036s;
    }
    .lds-roller div:nth-child(1):after {
      top: 63px;
      left: 63px;
    }
    .lds-roller div:nth-child(2) {
      animation-delay: -0.072s;
    }
    .lds-roller div:nth-child(2):after {
      top: 68px;
      left: 56px;
    }
    .lds-roller div:nth-child(3) {
      animation-delay: -0.108s;
    }
    .lds-roller div:nth-child(3):after {
      top: 71px;
      left: 48px;
    }
    .lds-roller div:nth-child(4) {
      animation-delay: -0.144s;
    }
    .lds-roller div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }
    .lds-roller div:nth-child(5) {
      animation-delay: -0.18s;
    }
    .lds-roller div:nth-child(5):after {
      top: 71px;
      left: 32px;
    }
    .lds-roller div:nth-child(6) {
      animation-delay: -0.216s;
    }
    .lds-roller div:nth-child(6):after {
      top: 68px;
      left: 24px;
    }
    .lds-roller div:nth-child(7) {
      animation-delay: -0.252s;
    }
    .lds-roller div:nth-child(7):after {
      top: 63px;
      left: 17px;
    }
    .lds-roller div:nth-child(8) {
      animation-delay: -0.288s;
    }
    .lds-roller div:nth-child(8):after {
      top: 56px;
      left: 12px;
    }
    @keyframes lds-roller {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .infoPopup {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 400;
    width: 90%;
    max-width: 400px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;

    border-radius: 10px;

    .closeBtn {
      align-self: flex-end;
      width: 60px;
      height: 60px;
      margin: 10px 10px 5px 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .title {
      margin-top: -20px;
      padding: 15px;
      font-weight: 700;
    }

    .tips-text {
      margin-top: -15px;
      padding: 15px;
    }

    .disclaimer {
      padding: 15px;
      font-size: 0.7rem;
    }

    input {
      background: rgba(0, 0, 0, 0.05);
      color: #000000;
      width: calc(100% - 1.6rem);
      height: 45px;
      font-size: 100% !important;
      font-weight: bold !important;
      padding-left: 0.83333rem !important;
      padding-right: 0.83333rem !important;
      outline: none;
      border: 0px solid !important;
      margin-bottom: 10px;
    }

    .actions {
      margin-top: 20px;

      .trackButton {
        width: 100%;
        text-align: center;
        padding: 1.11111rem 2.22222rem 1.16667rem 2.22222rem !important;
        background: linear-gradient(90deg, rgba(190, 53, 11, 1) 0%, rgba(251, 139, 0, 1) 100%);
        border: 0px solid !important;
        text-transform: uppercase;
        font-size: 0.77778rem !important;
        border-color: #ffffff;
        color: #ffffff;
        cursor: pointer;

        &:hover {
          opacity: 1;
          background: linear-gradient(
                          90deg, rgba(251, 139, 0, 1) 0%, rgba(190, 53, 11, 1) 100%);
          border: 0px solid !important;
          border-color: #ffffff;
        }

        &:focus {
          background-color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}
