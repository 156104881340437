.header {
  flex: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 13vh;
  max-height: 85px;
  background-color: #0D0D0D;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);

  &.mobile {
    flex-direction: column;
    height: auto;

    .headerLogo {
      margin-bottom: 15px;
    }
  }

  .headerLogo {
    height: 8vh;
    max-height: 45px;
  }

  .themeHeader {
    position: absolute;
    left: 20px;

    .backButton {
      width: 30px;
      height: 30px;
    }
  }

  .userInfoMobile {
    margin-top: 2px;
    color: #FFFFFF;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 10px;
    margin-left: auto;

    .person {
      width: 14px;
      margin-right: 8px;
    }

    .logout {
      width: 14px;
      margin-left: 20px;
      margin-right: 10px;
    }
  }

  .userInfo {
    color: #FFFFFF;
    position: absolute;
    right: 40px;
    display: flex;
    align-items: center;

    .person {
      width: 20px;
      margin-right: 8px;
    }

    .logout {
      width: 20px;
      margin-left: 40px;
    }
  }
}
