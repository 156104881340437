.listsMenu {
  display: flex;
  width: 100%;

  .link {
    flex: 1;
    color: #000000;

    .menuButton {
      width: 100%;
      background: white;
      border: none;
      height: 30px;

      &.active {
        background-color: #ff5722;
        color: #FFFFFF;
      }

      &.first {
        border-bottom-left-radius: 10px;
        border-right: solid 1px #2c2c2c;
      }

      &.middle {
        border-right: solid 1px #2c2c2c;
      }
      &.last {
        border-bottom-right-radius: 10px;
      }
    }
  }
}
