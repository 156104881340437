.musicPlayer {
  flex: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 88px;
  background-color: #2c2c2c;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
  align-items: center;
  z-index: 100;

  .playButton {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .playBtn {
    width: 35px;
  }
}
