.tilesContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;

  .noResults {
    width: 100%;
    color: #FFFFFF;
    text-align: center;
    margin-top: 60px;
  }

  .themeHeader {
    display: flex;
    align-items: center;

    .backButton {
      margin: 10px 20px;
      width: 30px;
      height: 30px;
    }

    .themeTitle {
      color: #FFFFFF;
      font-size: 15px;
      font-weight: 800;
    }
  }

  .line {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .searchBox {
    line-height: 3em;
    font-size: 1em;
    background-color: #232323;
    color: #FFFFFF;
    outline: none;
    border: none;
    border-bottom: solid 0.3px rgba(199, 199, 199, 0.56);
    text-indent: 2em;
    height: 3.5em;
  }

  .tileList {
    text-align: left;
    z-index: 2;
    overflow-y: auto;
    margin-bottom: 3em;

    .noResults {
      color: #FFFFFF;
      text-align: center;
      margin-top: 60px;
    }

    .emptyBox {
      display: flex;
      flex-direction: column;
      align-items: center;

      .addButton {
        width: 70px;
        align-items: center;
        align-self: center;
        margin-top: 40px;
      }
    }


    .tile {
      color: #FFFFFF;
      display: flex;
      flex-direction: row;
      padding: 10px 25px;

      &.active {
        background-color: #ff5722;
      }

      .themeIcon {
        position: relative;
        min-width: 87px;
        min-height: 87px;
        width: 87px;
        height: 87px;

        .tileBackgroundTheme {
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
        .listCount {
          position: absolute;
          z-index: 15;
          right: 8px;
          top: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          border: 1px #ffffff solid;
          background-color: #0d0d0d;
          border-radius: 12.5px;
          font-size: 10px;
          line-height: 10px;
        }
        .tileImgTheme {
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 10;
          min-width: 72px;
          min-height: 72px;
          max-width: 72px;
          max-height: 72px;

          -webkit-user-drag: none;
          -khtml-user-drag: none;
          -moz-user-drag: none;
          -o-user-drag: none;
          user-drag: none;
        }
      }

      .playlistIcon {
        .tileImg {
          margin-top: 5px;
          min-width: 87px;
          min-height: 87px;
          max-width: 87px;
          max-height: 87px;

          -webkit-user-drag: none;
          -khtml-user-drag: none;
          -moz-user-drag: none;
          -o-user-drag: none;
          user-drag: none;
        }
      }

      .tileInfo {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        width: 100%;

        .upper {
          display: flex;
          flex-direction: row;
          align-items: center;

          .tileFavorite {
            margin-left: auto;
            width: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #333333;

            img {
              width: 100%;
            }

            &.active {
              background-color: #ff5722;
            }
          }

          .tileTitle {
            font-size: 17px;
            font-weight: 500;
          }
        }

        .tileTagline {
          text-align: left;
          margin-top: 10px;
        }
      }


    }

    .tile + .tile {
      padding-top: 8px;
      padding-bottom: 8px;
      border-top: 1px solid black;
    }
  }

  .loadingSpinner {
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px;

    .loadingText {
      color: #FFFFFF;
      font-size: 13px;
      margin-top: 20px;
    }

    .lds-roller {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-roller div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
    }
    .lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #333333;
      margin: -4px 0 0 -4px;
    }
    .lds-roller div:nth-child(1) {
      animation-delay: -0.036s;
    }
    .lds-roller div:nth-child(1):after {
      top: 63px;
      left: 63px;
    }
    .lds-roller div:nth-child(2) {
      animation-delay: -0.072s;
    }
    .lds-roller div:nth-child(2):after {
      top: 68px;
      left: 56px;
    }
    .lds-roller div:nth-child(3) {
      animation-delay: -0.108s;
    }
    .lds-roller div:nth-child(3):after {
      top: 71px;
      left: 48px;
    }
    .lds-roller div:nth-child(4) {
      animation-delay: -0.144s;
    }
    .lds-roller div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }
    .lds-roller div:nth-child(5) {
      animation-delay: -0.18s;
    }
    .lds-roller div:nth-child(5):after {
      top: 71px;
      left: 32px;
    }
    .lds-roller div:nth-child(6) {
      animation-delay: -0.216s;
    }
    .lds-roller div:nth-child(6):after {
      top: 68px;
      left: 24px;
    }
    .lds-roller div:nth-child(7) {
      animation-delay: -0.252s;
    }
    .lds-roller div:nth-child(7):after {
      top: 63px;
      left: 17px;
    }
    .lds-roller div:nth-child(8) {
      animation-delay: -0.288s;
    }
    .lds-roller div:nth-child(8):after {
      top: 56px;
      left: 12px;
    }
    @keyframes lds-roller {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .fadeOut {
    min-height: 100%;
    min-width: 35px;
    margin-left: -35px;
    z-index: 4;
    background: -webkit-linear-gradient(90deg,
                    rgba(32, 32, 32, 0) 0%,
                    rgba(32, 32, 32, 1) 100%
    );
    background-image: -moz-linear-gradient(90deg,
                    rgba(32, 32, 32, 0) 0%,
                    rgba(32, 32, 32, 1) 100%
    );
    background-image: -o-linear-gradient(90deg,
                    rgba(32, 32, 32, 0) 0%,
                    rgba(32, 32, 32, 1) 100%
    );
    background-image: linear-gradient(90deg,
                    rgba(32, 32, 32, 0) 0%,
                    rgba(32, 32, 32, 1) 100%
    );
    background-image: -ms-linear-gradient(90deg,
                    rgba(32, 32, 32, 0) 0%,
                    rgba(32, 32, 32, 1) 100%
    );
  }

  .infoPopup {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 400;
    width: 90%;
    max-width: 400px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;

    border-radius: 10px;

    .closeBtn {
      align-self: flex-end;
      width: 60px;
      height: 60px;
      margin: 10px 10px 5px 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
      }
    }

    label {
      display: flex;
      margin-left: 0.8rem;
      width: calc(100% - 1.6rem);
      text-align: left;
      font-size: 0.66667rem;
      margin-top: 0px;
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    input {
      background: rgba(0,0,0, 0.05);
      color: #000000;
      width: calc(100% - 1.6rem);
      height: 45px;
      font-weight: bold;
      padding-left: 0.83333rem;
      padding-right: 0.83333rem;
      outline: none;
      border: 0px solid!important;
      margin-bottom: 10px;

      &:focus &:hover {
        background: rgba(0,0,0, 0.1);
        outline: 0;
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    .actions {
      margin-bottom: 20px;

      .trackButton {
        width: 100%;
        text-align: center;
        padding: 1.11111rem 2.22222rem 1.16667rem 2.22222rem;
        background: linear-gradient(90deg, rgba(190, 53, 11, 1) 0%, rgba(251, 139, 0, 1) 100%);
        border: 0px solid !important;
        text-transform: uppercase;
        font-size: 0.77778rem;
        border-color: #ffffff;
        color: #ffffff;
        cursor: pointer;

        &:hover {
          opacity: 1;
          background: linear-gradient(
                          90deg, rgba(251, 139, 0, 1) 0%, rgba(190, 53, 11, 1) 100%);
          border: 0px solid !important;
          border-color: #ffffff;
        }

        &:focus {
          background-color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }

  .errorBar {
    background-color: #fd8a8a;
    color: #fff;
    list-style: none;
    width: 100%;
    margin: 0;
    overflow: hidden;
    z-index: 9999;
    -webkit-transition: opacity 1s ease;
    -o-transition: opacity 1s ease;
    transition: opacity 1s ease;
    font-size: 0.72222rem;
    line-height: 0.94444rem;
    font-weight: bold;
    padding: 20px;
    cursor: pointer;

    .inner {
      transition: opacity .5s;
      padding: 0px 35px 0 10px;
      margin-left: 20px;
      a {
        color: #FFFFFF;
      }
    }
    &.errorBar:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-exclamation-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z'/%3E%3C/svg%3E");
      color: #FFFFFF;
      display: block;
    }
  }
}
