.mobileMenuContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #333333;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
  align-items: center;
  justify-content: space-around;
  z-index: 2;

  .link {
    text-decoration: none;
    align-self: center;
  }

  .menuButton {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 85px;
    margin: 8px 0 0;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    border: none;
    outline: none;
    background-color: transparent;

    .activeCount {
      position: absolute;
      top: 5px;
      left: 27px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1.6px #ffffff solid;
      color: #ffffff;
      background-color: rgba(13, 13, 13, 0.75);
      border-radius: 20px;
      font-size: 13px;
      line-height: 13px;
    }

    img {
      height: 30px;
    }

    .buttonText {
      font-size: 12px;
      margin-top: 10px;
      font-weight: 600;
    }
    &.active {
      color: #ff5722;

    }

  }
}
