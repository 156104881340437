.menuContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #202020;
  z-index: 20;

  .link {
    text-decoration: none;
    flex-grow: 1;
    display: flex;
  }

  .menuButton {
    display: flex;
    flex-direction: column;
    width: 17vw;
    max-width: 210px;
    margin: 8px 0 0;
    align-items: center;
    justify-content: center;
    background-color: #333333;
    color: #FFFFFF;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    border: none;
    outline: none;

    &.first {
      margin: 0;
    }

    &.active {
      background-color: #ff5722;
    }

    .activeCount {
      position: absolute;
      margin-bottom: 14vh;
      margin-left: 7vh;
      width: 4vh;
      height: 4vh;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1.6px #ffffff solid;
      background-color: rgba(13, 13, 13, 0.75);
      border-radius: 20px;
      font-size: 2vh;
      line-height: 17px;
    }
    img {
      height: 7vh;
      max-height: 50px;
    }

    .buttonText {
      font-size: 3vh;
      margin-top: 10px;
      font-weight: 600;
    }

    @media screen and (min-height: 500px) {
      .buttonText {
        font-size: 14px;
      }
    }
  }
}
