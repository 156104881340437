.mobileContentContainer {
  width: 100%;
  min-width:0;
  flex: auto;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #202020;

  .listsMenu .menuButton {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mobileLoadingContainer {
  display: flex;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
}
