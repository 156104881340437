.trackPlayer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .infoPopup {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 400;
    width: 90%;
    max-width: 400px;
    min-height: 300px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;

    border-radius: 10px;

    .trackName {
      margin-top: -20px;
    }

    .closeBtn {
      align-self: flex-end;
      width: 60px;
      height: 60px;
      margin: 10px 10px 5px 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .playlistBlock {
      display: flex;
      flex-direction: row;
      margin: 12px;
      background-color: #2c2c2c;
      padding: 10px;
      color: #FFFFFF;
      align-items: center;

      .playlistImage {
        margin-right: 10px;
        width: 100px;
        height: 100px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .playlistDetails {
        flex: 1;
        text-align: left;

        .playlistTitle {
          font-size: 17px;
          font-weight: 500;
          margin-bottom: 6px;
        }

        .playlistDescription {

        }
      }
    }

    .banButton {
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
      padding: 1.11111rem 2.22222rem 1.16667rem 2.22222rem;
      background: linear-gradient(90deg, rgba(190,53,11,1) 0%, rgba(251,139,0,1) 100%);
      border: 0px solid!important;
      text-transform: uppercase;
      font-size: 0.77778rem;
      border-color: #ffffff;
      color: #ffffff;
      cursor: pointer;

      &:hover{
        opacity: 1;
        background: linear-gradient(
                        90deg
                , rgba(251,139,0,1) 0%, rgba(190,53,11,1) 100%);
        border: 0px solid!important;
        border-color: #ffffff;
      }

      &:focus {
        background-color: rgba(0,0,0, 0.6);
      }
    }
  }

  .playerInfo {
    width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .upper {
    display: flex;
    align-items: center;
  }

  .lower {
    display: flex;
    margin-left: 22px;
    margin-right: 22px;

    .nextTrack {
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
      overflow: hidden;
      color: #868686;
    }
  }
  .trackInfo {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    text-align: left;
    color: #FFFFFF;
    margin-left: 22px;
    font-weight: 700;

    font-size: 14px;
  }

  .infoBtn {
    width: 20px;
    height: 20px;
    margin-left: auto;
  }


  .durationInfo {
    margin-left: 6px;
    display: flex;
    width: 87px;
    min-width: 87px;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    color: #d0d0d0;
    text-align: left;
    justify-content: center;

    .timer {
      text-align: left;
      width: 50px;
    }
  }

  .actions {
    background-color: #232323;
    height: 100%;
    min-height: 100%;
    width: 80px;
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nextBtn {
    width: 25px;
    height: 25px;
    z-index: 10;
    cursor: pointer;

    &.inactive {
      opacity: 0.5;
    }
  }

  @keyframes anim {
    0% {
      height: 10px;
    }
    50% {
      height: 40px;
    }
    100% {
      height: 10px;
    }
  }
  @-webkit-keyframes anim {
    0% {
      height: 10px;
    }
    50% {
      height: 40px;
    }
    100% {
      height: 10px;
    }
  }
  @-moz-keyframes anim {
    0% {
      height: 10px;
    }
    50% {
      height: 40px;
    }
    100% {
      height: 10px;
    }
  }
  @-ms-keyframes anim {
    0% {
      height: 10px;
    }
    50% {
      height: 40px;
    }
    100% {
      height: 10px;
    }
  }
  @-ie-keyframes anim {
    0% {
      height: 10px;
    }
    50% {
      height: 40px;
    }
    100% {
      height: 10px;
    }
  }
  * {
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    box-sizing: border-box;
  }

  .bar {
    width: 8px;
    height: 100%;
    background-color: #ff5722;
    position: absolute;
    bottom: 0;
    margin-bottom: -2px;
    -webkit-animation-name: anim;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-name: anim;
    -moz-animation-iteration-count: infinite;
    -ms-animation-name: anim;
    -ms-animation-iteration-count: infinite;
    -o-animation-name: anim;
    -o-animation-iteration-count: infinite;
    animation-name: anim;
    animation-iteration-count: infinite;
  }

  #bar-one {
    -webkit-animation-duration: 1.9s;
    -moz-animation-duration: 1.9s;
    -ms-animation-duration: 1.9s;
    -o-animation-duration: 1.9s;
    animation-duration: 1.9s;
    left: 0px;
  }

  #bar-two {
    -webkit-animation-duration: 0.7s;
    -moz-animation-duration: 0.7s;
    -ms-animation-duration: 0.7s;
    -o-animation-duration: 0.7s;
    animation-duration: 0.7s;
    left: 10px;
  }

  #bar-three {
    -webkit-animation-duration: 1.1s;
    -moz-animation-duration: 1.1s;
    -ms-animation-duration: 1.1s;
    -o-animation-duration: 1.1s;
    animation-duration: 1.1s;
    left: 20px;
  }

  spinner {
    height: 40px;
    width: 30px;
    display: block;
    overflow: hidden;
  }
}
